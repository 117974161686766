import React from "react"
import Helmet from "react-helmet"

class Application extends React.Component {
  render() {
    return (
      <div className="application" id="wedding">
        <Helmet>
          <meta charSet="utf-8" />          
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta name="googlebot" content="noindex" />
          <meta name="robots" content="noindex" />
          <meta name="description" value="Krithika + Girish's wedding website" />
          <title>Krithika + Girish are married!</title>
        </Helmet>

        <img src="/hands.jpg" title="Thanks for celebrating with us!" alt="Thanks for celebrating with us!" />
        <p>#krithish ♥</p>
      </div>
    );
  }
};

export default Application;